import axios from 'axios';

const baseUrl = 'https://iklan.sircledev.com/api';

export const get = (url) => {
    return axios.get(baseUrl + url)
        .then(response => {
            return response.data;
        });
}

export const post = (url, data, config) => {
    return axios.post(baseUrl + url, data, config)
        .then(response => {
            return response.data;
        });
}
