import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Pembayaran from '../views/Pembayaran.vue'
import AdminLogin from '../views/AdminLogin.vue'
import Admin from '../views/Admin.vue'
import AdminDetail from '../views/AdminDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pembayaran/:id',
    name: 'Pembayaran',
    component: Pembayaran
  },
  {
    path: '/admin/login',
    name: 'admin',
    component: AdminLogin
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin
  },
  {
    path: '/admin/detail/:hash',
    name: 'admin',
    component: AdminDetail
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
