<template>
  <div class="admin">
    <h2>Admin</h2>

    <div class="filter">
      <div class="form-group">
        <label for="">Filter status</label>
        <div class="grid">
          <select v-model="filter.status">
            <option value="ALL">Semua</option>
            <option value="LUNAS">Lunas</option>
            <option value="PENDING">Pending</option>
            <option value="POSTING">Diposting</option>
          </select>
          <button type="button" @click="load()" :disabled="loading">
            <span class="material-symbols-outlined"> search </span>
          </button>
        </div>
      </div>
    </div>

    <br />

    <ul>
      <li
        v-for="(item, index) in data"
        :key="index"
        @click="$router.push(`/admin/detail/${item.hash}`)"
      >
        <div>
          <p>
            <b>{{ item.nama }}</b>
            <br />
            {{ item.no_wa }}
          </p>
          <small>
            {{ item.created_at }}
          </small>
        </div>
        <div>
          <span :class="item.status.toLowerCase()">{{ item.status }}</span>
        </div>
      </li>
    </ul>

    <p v-show="data.length <= 0" style="text-align: center; padding: 20px 0 60px">
      Tidak ada data untuk ditampilkan
    </p>
  </div>
</template>

<script>
import * as api from "@/api";

export default {
  data() {
    return {
      loading: false,

      filter: {
        status: "LUNAS",
      },
      data: {},
    };
  },
  methods: {
    load() {
      this.loading = true;
      api.get(`/v1/iklan/get?status=${this.filter.status}`).then((r) => {
        this.loading = false;
        this.data = r.data;
      });
    },
  },
  mounted() {
    if (!localStorage.getItem('admin_login')) {
      this.$router.push('/admin/login');
    }
    this.load();
  },
};
</script>

<style scoped lang="scss">
p {
  margin: 0;
  font-size: 14px;
}
h1 {
  margin-top: 0;
}

.filter {
  .grid {
    display: grid;
    grid-template-columns: 1fr 60px;
    grid-column-gap: 15px;
  }
  button {
    font-weight: 900;
    height: 42px;
  }
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
    font-size: 14px;

    &:active {
      background-color: #eee;
    }

    p {
      margin-bottom: 5px;
    }

    small {
      color: #777;
    }

    span {
      font-size: 12px;
      background: #eee;
      padding: 4px 10px;
      border-radius: 6px;
      margin-left: 10px;
      &.pending {
        background: orangered;
        color: #fff;
      }
      &.lunas {
        background: green;
        color: #fff;
      }

      &.posting {
        background: var(--primary);
        color: #fff;
      }
    }
  }
}
</style>