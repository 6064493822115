<template>
  <div class="home">
    <form autocomplete="off" @submit.prevent="submit()">
      <p>
        <b>Kontak</b>
      </p>
      <div class="form-group">
        <label>Nama</label>
        <input
          type="text"
          v-model="data.nama"
          :class="{ invalid: submitted && !data.nama }"
        />
        <small v-show="submitted && !data.nama">Wajib diisi</small>
      </div>
      <div class="form-group">
        <label>No Whatsapp</label>
        <input
          type="tel"
          placeholder="Gunakan format 08xx"
          v-model="data.no_wa"
          :class="{ invalid: submitted && !data.no_wa }"
        />
        <small v-show="submitted && !data.no_wa">Wajib diisi</small>
      </div>
      <div class="form-group">
        <label>Email</label>
        <input
          type="email"
          v-model="data.email"
          :class="{ invalid: submitted && !data.email }"
        />
        <small v-show="submitted && !data.email">Wajib diisi</small>
      </div>
      <br />
      <p>
        <b>Properti</b>
      </p>
      <div class="form-group">
        <label for="">Jenis Properti</label>
        <select v-model="data.jenis_properti">
          <option value="Kos">Kos</option>
          <option value="Rumah">Rumah</option>
        </select>
      </div>
      <div class="form-group">
        <label>Harga</label>
        <div class="grid">
          <input
            type="number"
            v-model="data.harga"
            :class="{ invalid: submitted && !data.harga }"
          />
          <select v-model="data.per">
            <option value="Bulanan">Bulanan</option>
            <option value="Tahunan">Tahunan</option>
          </select>
        </div>
        <small v-show="submitted && !data.harga">Wajib diisi</small>
      </div>
      <div class="form-group">
        <label for="">Keterangan</label>
        <textarea
          rows="12"
          v-model="data.keterangan"
          :class="{ invalid: submitted && !data.keterangan }"
          placeholder="Alamat:
- tuliskan alamat

Spesifikasi: 
- jumlah kamar
- luas bangunan
- listrik
- dll

Fasilitas umum:
- security
- dll
"
        ></textarea>
        <small v-show="submitted && !data.keterangan">Wajib diisi</small>
      </div>

      <br />
      <p>
        <b>Gambar</b>
      </p>

      <div class="form-group">
        <input
          type="file"
          multiple
          accept="image/jpg, image/webp"
          @change="checkFiles"
          :class="{ invalid: submitted && files.length <= 0 }"
        />
        <small>Pilih bebebrapa sekaligus, maksimal 9 gambar</small>
      </div>
      <div class="gambar">
        <img :src="item" v-for="(item, index) in gambar" :key="index" />
      </div>

      <div class="form-group" style="margin-top: 20px">
        <button type="submit">LANJUT BAYAR (Rp 50.000)</button>
      </div>
    </form>

    <div class="modal" v-if="showModal">
      <div class="modal-body" v-if="showModal == 'BAYAR'">
        <h3>Pilih metode bayar</h3>

        <ul class="pembayaran">
          <li @click="pembayaran = 'mandiri'" v-show="false">
            <div>
              <img
                src="https://d2f3dnusg0rbp7.cloudfront.net/snap-preferences/production/v1/logos/mandiri.svg"
                alt=""
              />
            </div>
            <div>Mandiri</div>
            <div>
              <span
                class="material-symbols-outlined"
                v-show="pembayaran == 'mandiri'"
              >
                check_circle
              </span>
            </div>
          </li>
          <li @click="pembayaran = 'bni'">
            <div>
              <img
                src="https://d2f3dnusg0rbp7.cloudfront.net/snap-preferences/production/v1/logos/bni.svg"
                alt=""
              />
            </div>
            <div>BNI</div>
            <div>
              <span
                class="material-symbols-outlined"
                v-show="pembayaran == 'bni'"
              >
                check_circle
              </span>
            </div>
          </li>
          <li @click="pembayaran = 'bri'">
            <div>
              <img
                src="https://d2f3dnusg0rbp7.cloudfront.net/snap-preferences/production/v1/logos/bri.svg"
                alt=""
              />
            </div>
            <div>BRI</div>
            <div>
              <span
                class="material-symbols-outlined"
                v-show="pembayaran == 'bri'"
              >
                check_circle
              </span>
            </div>
          </li>
          <li @click="pembayaran = 'permata'">
            <div>
              <img
                src="https://d2f3dnusg0rbp7.cloudfront.net/snap-preferences/production/v1/logos/permata.svg"
                alt=""
              />
            </div>
            <div>Permata</div>
            <div>
              <span
                class="material-symbols-outlined"
                v-show="pembayaran == 'permata'"
              >
                check_circle
              </span>
            </div>
          </li>
          <li @click="pembayaran = 'qris'">
            <div>
              <img
                src="https://d2f3dnusg0rbp7.cloudfront.net/snap-preferences/production/v1/logos/qris.svg"
                alt=""
              />
            </div>
            <div>QRIS</div>
            <div>
              <span
                class="material-symbols-outlined"
                v-show="pembayaran == 'qris'"
              >
                check_circle
              </span>
            </div>
          </li>
        </ul>
        <br />
        <button type="button" @click="submit()" v-show="!loading">
          BAYAR SEKARANG
        </button>
        <button type="submit" v-show="loading" disabled class="loading">
          <svg
            class="spinner"
            width="22px"
            height="22px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              class="path"
              fill="none"
              stroke-width="6"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </button>
      </div>

      <div class="modal-body" v-if="showModal == 'AGREEMENT'">
        <p style="text-align: center">
          <img src="https://iklan.sircledev.com/public/dist/logo.jpeg" width="100" />
        </p>

        <p>
          <b>Selamat datang di Info Kontrakan Jogja Update</b>
          <br />
          <br />
          Berikut ketentuan untuk posting iklan:
        </p>
        <ul>
          <li>Biaya posting iklan adalah <b>Rp 50.000</b></li>
          <li>
            Iklan akan diposting di instagram
            <a
              href="https://instagram.com/kontrakanjogjaupdate"
              target="_blank"
              style="color: var(--primary)"
            >
              @kontrakanjogjaupdate
            </a>
          </li>
          <li>Iklan akan diposting maksimal 2 jam setelah pembayaran</li>
          <li>Iklan akan diletakkan di highlight terbaru</li>
          <li>Iklan akan diposting di story</li>
          <li>Iklan akan diposting ulang 1x setelah 5 hari</li>
          <li>Pembayaran tidak dapat dikembalikan</li>
        </ul>

        <br />

        <button type="button" @click="agree()">Setuju</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/api";

export default {
  name: "Home",
  data() {
    return {
      showModal: null,
      loading: false,
      submitted: false,

      data: {
        no_wa: "08",
        jenis_properti: "Kos",
        per: "Tahunan",
      },
      gambar: [],
      files: [],

      pembayaran: "",
    };
  },
  methods: {
    checkFiles(e) {
      this.files = [];
      this.gambar = [];

      const files = e.target.files;

      for (let i = 0; i < 9; i++) {
        this.files.push(files[i]);
        this.gambar.push(URL.createObjectURL(files[i]));
      }
    },
    submit() {
      this.submitted = true;

      if (
        !this.data.nama ||
        !this.data.no_wa ||
        !this.data.email ||
        !this.data.harga ||
        !this.data.keterangan ||
        this.files.length <= 0
      ) {
        return;
      }

      if (this.showModal == null) {
        this.showModal = "BAYAR";
        document.querySelector("body").style.overflow = "hidden";
        return;
      }

      if (!this.pembayaran) {
        alert('Silakan pilih metode pembayaran');
        return;
      }

      this.loading = true;

      const payload = new FormData();
      payload.append("pembayaran", this.pembayaran);

      Object.keys(this.data).forEach((key) => {
        payload.append(key, this.data[key]);
      });

      this.files.forEach((file, index) => {
        payload.append(`gambar_${index}`, file);
      });

      api
        .post("/v1/iklan/submit", payload)
        .then((r) => {
          document.querySelector("body").style.overflow = "auto";

          if (r.valid) {
            this.$router.push(`/pembayaran/${r.data.hash}`);
          }
        })
        .catch((e) => {
          alert('Server error. Silakan coba beberapa saat lagi');
        });
    },
    agree() {
      localStorage.setItem("modal_closed", true);
      this.showModal = null;
      document.querySelector("body").style.overflow = "auto";
    },
  },
  mounted() {
    if (!localStorage.getItem("modal_closed")) {
      this.showModal = "AGREEMENT";
      document.querySelector("body").style.overflow = "hidden";
    }
  },
};
</script>

<style scoped lang="scss">
p {
  margin: 0;
  margin-bottom: 15px;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-column-gap: 15px;
}

.form-group {
  position: relative;
  span {
    position: absolute;
    right: 5px;
    margin-top: 10px;
  }
  small {
    color: red;
  }
}

.gambar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;

  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width: 768px) {
      height: 120px;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;

  .modal-body {
    background: #fff;
    width: 400px;
    height: fit-content;
    margin-top: 100px;
    border-radius: 10px;
    padding: 20px;

    h3 {
      margin: 0;
    }

    @media screen and (max-width: 768px) {
      margin: 40px 20px;
    }

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      margin-top: -20px;
    }

    p,
    ul {
      font-size: 14px;
      color: #555;
    }

    ul {
      margin: 0;
      padding: 0;
      padding-left: 20px;
      &.pembayaran {
        padding: 0;
        li {
          display: flex;
          align-items: center;
          height: 45px;
          border-bottom: 1px solid #ddd;
          &:active {
            background-color: #f3f3f3;
          }
          img {
            height: auto;
            width: 34px;
            margin-right: 10px;
          }

          > div {
            &:last-child {
              display: flex;
              align-items: center;
              margin-left: auto;
              color: green;
            }
          }
        }
      }
    }
  }
}
</style>