<template>
  <div class="pembayaran">
    <h3>Invoice #{{ data.no_invoice }}</h3>
    <small>Bill to:</small>
    <div>
      <p>
        <b>{{ data.nama }}</b> <br />
        {{ data.no_wa }}
      </p>
    </div>

    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Keterangan</th>
          <th style="text-align: right">Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Posting iklan</td>
          <td align="right">50.000</td>
        </tr>
      </tbody>
    </table>

    <div class="total">
      <p>Total Tagihan</p>
      <h1>Rp 50.000</h1>
    </div>

    <br />

    <div class="bank" v-if="data.pembayaran" :class="data.status.toLowerCase()">
      <h3 style="font-weight: 500">Pembayaran</h3>

      <div v-if="data.status == 'LUNAS'">
        <h1>LUNAS</h1>
        <p>Dibayar pada {{ data.paid_at }}</p>
      </div>

      <div v-if="data.status == 'PENDING'">
        <p v-if="data.pembayaran != 'qris'">Bank</p>
        <h2>{{ data.pembayaran.toUpperCase() }}</h2>

        <p v-if="data.pembayaran != 'qris'">No Virtual Account</p>
        <h2 v-if="data.pembayaran == 'permata'">
          {{ data.log_pembayaran.permata_va_number }}
        </h2>

        <h2 v-if="data.pembayaran == 'bri' || data.pembayaran == 'bni'">
          {{ data.log_pembayaran.va_numbers[0].va_number }}
        </h2>
      </div>

      <div v-if="data.pembayaran == 'qris'">
        <img
          src="https://api.sandbox.midtrans.com/v2/qris/668f0a68-9fbb-470c-a368-492b442b7676/qr-code"
          width="100%"
        />
      </div>
    </div>
    <p style="margin-top: 5px" v-if="data.status == 'PENDING'">
      Bayar sebelum {{ data.expired }}
    </p>
  </div>
</template>

<script>
import * as api from "@/api";

export default {
  data() {
    return {
      data: {},
    };
  },
  methods: {
    load() {
      api.get(`/v1/iklan/get/${this.$route.params.id}`).then((r) => {
        this.data = r.data;
      });
    },
  },
  mounted() {
    this.load();

    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 500);
    document.querySelector("body").style.overflow = "auto";
  },
};
</script>

<style scoped lang="scss">
p {
  margin: 0;
  font-size: 14px;
}
h1 {
  margin-top: 0;
}

table {
  padding: 0;
  margin: 20px 0 0;
  border-collapse: collapse;
  width: 100%;

  thead {
    tr {
      th {
        font-size: 14px;
        background: #ddd;
        padding: 10px 5px;
        text-align: left;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 10px 5px;
        font-size: 14px;
        color: #555;
        border-bottom: 1px solid #ddd;
      }
    }
  }
}

.total {
  margin-top: 20px;
  text-align: right;
}

.bank {
  background: var(--primary);
  border-radius: 6px;
  padding: 15px;
  color: #fff;

  &.lunas {
    background: rgb(103, 206, 78);
  }

  h3 {
    margin: 0 0 20px 0;
  }
  p,
  h2 {
    margin: 0;
  }
  h2 {
    margin-bottom: 10px;
  }
}
</style>