<template>
  <div id="app">
    <p class="logo">
      <img src="https://iklan.sircledev.com/public/dist/logo.jpeg" width="160" />
    </p>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.path = this.$route.name;
    }, 10);
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,300,0,0");

* {
  font-family: "Poppins", sans-serif;
}

:root {
  --primary-lighter: #5fb2ff;
  --primary: #0083fb;
  --primary-darker: #0071da;
}

html,
body {
  background: #eff8ff;
  padding: 0;
  margin: 0;
}
#app {
  max-width: 500px;
  margin: 40px auto;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 0px 1px #333;
  border-radius: 10px;
  border-top: 10px solid var(--primary-lighter);

  @media screen and (max-width: 768px) {
    border-radius: 0;
    box-shadow: 0;
    margin: 0;
    padding: 40px 20px;
  }
}

#admin {
  max-width: 1140px;
  margin: 20px auto;
  background: #fff;
  padding: 20px;
  box-shadow: 0px 1px 3px #ccc;
  border-radius: 4px;
}

.logo {
  text-align: center;
  @media screen and (max-width: 768px) {
    img {
      margin-top: -40px;
    }
  }
}
.form-group {
  margin-bottom: 10px;

  label {
    display: block;
    margin-bottom: 4px;
    font-size: 14px;
  }
}

input,
select,
textarea {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 6px;
  outline: none;
  width: 100%;
  background: #fff;

  &:focus {
    border: 1px solid var(--primary);
  }

  &.invalid {
    border: 1px solid red;
  }
}

input,
textarea {
  width: calc(100% - 22px);
}

button {
  background: var(--primary);
  padding: 10px;
  border: 0;
  outline: none;
  border-radius: 6px;
  color: #fff;
  width: 100%;
  font-size: 14px;
  cursor: pointer;

  &.outline {
    background: #fff;
    border: 1px solid var(--primary);
    color: var(--primary);
  }

  &:active {
    background: var(--primary-darker);
  }

  &:disabled {
    background: var(--primary-lighter);
  }
}

$offset: 187;
$duration: 1.4s;

.spinner {
  animation: rotator $duration linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke: #fff;
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash $duration ease-in-out infinite;

  &.blue {
    stroke: var(--primary);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }
  50% {
    stroke-dashoffset: $offset/4;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
</style>
