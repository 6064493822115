<template>
  <div class="login">
    <form @submit.prevent="login()">
      <div class="form-group">
        <label>Username</label>
        <input type="text" v-model="data.username" />
      </div>
      <div class="form-group">
        <label>Password</label>
        <input type="password" v-model="data.password" />
      </div>
      <small v-show="!res.valid" style="display: block; color: red"
        >Login gagal</small
      >
      <br />
      <button type="submit" :disabled="loading">
        <label v-if="!loading">LOGIN</label>
        <svg
          v-if="loading"
          class="spinner"
          width="20px"
          height="20px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            class="path"
            fill="none"
            stroke-width="6"
            stroke-linecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </button>
    </form>
  </div>
</template>

<script>
import * as api from "../api";
export default {
  data() {
    return {
      loading: false,
      data: {},
      res: {
        valid: true,
      },
    };
  },
  methods: {
    async login() {
      const data = new FormData();
      data.append("username", this.data.username);
      data.append("password", this.data.password);

      this.loading = true;
      this.res = await api.post("/v1/login/auth", data);

      if (this.res.valid) {
        localStorage.setItem("admin_login", 1);
        this.$router.push("/admin");
      }

      this.loading = false;
    },
  },
  mounted() {
    if (localStorage.getItem("admin_login") == 1) {
      this.$router.push("/admin");
    }
  },
};
</script>