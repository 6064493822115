<template>
  <div class="admin">
    <div v-if="loadingData">
      <p style="text-align: center">
        <svg
          class="spinner"
          width="22px"
          height="22px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            class="path blue"
            fill="none"
            stroke-width="6"
            stroke-linecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
        <br />
        Loading...
      </p>
    </div>
    <div v-else>
      <h2>
        <span class="material-symbols-outlined" @click="$router.push('/admin')">
          arrow_back
        </span>
        Detail Iklan #{{ data.no_invoice }}
      </h2>

      <div class="grid">
        <div class="kontak">
          <h3>Kontak</h3>
          <table>
            <tr>
              <td>Nama</td>
              <td>:</td>
              <td>{{ data.nama }}</td>
            </tr>
            <tr>
              <td>No WA</td>
              <td>:</td>
              <td>{{ data.no_wa }}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>:</td>
              <td>{{ data.email }}</td>
            </tr>
          </table>

          <h3>Properti</h3>
          <table>
            <tr>
              <td>Jenis Properti</td>
              <td>:</td>
              <td>{{ data.jenis_properti }}</td>
            </tr>
            <tr>
              <td>Harga</td>
              <td>:</td>
              <td>{{ data.harga | number }} {{ data.per }}</td>
            </tr>
            <tr>
              <td>Keterangan</td>
              <td>:</td>
              <td></td>
            </tr>
          </table>

          <div class="keterangan">
            <div v-html="data.keterangan_html"></div>
          </div>

          <h3>Pembayaran</h3>
          <div class="pembayaran">
            <p>{{ data.pembayaran.toUpperCase() }}</p>
            <span :class="data.status.toLowerCase()">{{
              data.status.toUpperCase() == "POSTING"
                ? "SUDAH DIPOSTING"
                : data.status.toUpperCase()
            }}</span>
          </div>

          <button
            type="button"
            @click="posting()"
            v-show="!loading && data.status != 'POSTING'"
          >
            SUDAH DIPOSTING
          </button>
          <button type="button" v-show="loading" disabled>
            <svg
              class="spinner"
              width="22px"
              height="22px"
              viewBox="0 0 66 66"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                class="path"
                fill="none"
                stroke-width="6"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </button>
        </div>

        <div class="gallery">
          <div v-for="(item, index) in data.gambar" :key="index">
            <img :src="item.gambar" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/api";

export default {
  data() {
    return {
      loadingData: false,
      loading: false,

      data: {},
    };
  },
  methods: {
    load() {
      this.loadingData = true;
      api.get(`/v1/iklan/get/${this.$route.params.hash}`).then((r) => {
        this.loadingData = false;
        this.data = r.data;
      });
    },
    posting() {
      this.loading = true;

      const data = new FormData();
      data.append("id", this.data.id);

      api.post(`/v1/iklan/posting`, data).then((r) => {
        this.loading = false;
        location.reload();
      });
    },
  },
  mounted() {
    if (!localStorage.getItem("admin_login")) {
      this.$router.push("/admin/login");
    }
    this.load();
  },
};
</script>

<style scoped lang="scss">
h2 {
  margin: 0;
  font-weight: 400;
  display: flex;
  align-items: center;
  span {
    font-weight: 900;
    margin-right: 10px;
    cursor: pointer;
  }
}

table {
  border-collapse: collapse;
  td {
    font-size: 14px;
    &:nth-child(1) {
      width: 100px;
    }
    &:nth-child(2) {
      padding-right: 20px;
    }
  }
}

button {
  margin-top: 30px;
}

.pembayaran {
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }

  span {
    font-size: 12px;
    background: #eee;
    padding: 4px 10px;
    border-radius: 6px;
    margin-left: 10px;
    &.pending {
      background: orangered;
      color: #fff;
    }
    &.lunas {
      background: green;
      color: #fff;
    }

    &.posting {
      background: var(--primary);
      color: #fff;
    }
  }
}

.keterangan {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  padding: 15px;
  font-size: 14px;
  width: 300px;
}

.gallery {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;

  img {
    width: 100%;
  }
}
</style>